
@import '~bootstrap/dist/css/bootstrap.min.css';

:root {
  --primary-color: #03346E;
  --secondary-color: #555555;
} 

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color:white !important;
}
* {
  font-family: 'Open Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-weight: 500; */
}

.bg-cta-section {
  background: linear-gradient(30deg, #E9FAFB, #ffffff);
}

.bg-features-section {
  background: linear-gradient(210deg, #FEFCEB, #ffffff);
}

.bg-getstarted-section {
  background: linear-gradient(150deg, #EEEEEE, #ffffff);
}

textarea.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  /* background: #333333;
  color: #ffffff; */
}

a {
  text-decoration: none;
}

section .container {
  margin-right: auto;
  margin-left: auto;
  max-width: 950px; /* or 950px */
}